export const environment_org = {
  organization: 'ASD',
  auht0_redirectUri: {
    local: 'https://localhost:4200/login',
    dev: 'https://asd.front.g23-newpos-dev.net/login',
    stg: 'https://asd.front.stg.g23-newpos.net/login',
    prod: 'https://asd.front.pro.g23-newpos.net/login'
  },
  tableau_view: {
    new_goods: {
      dev: '/views/ASD/sheet0',
      stg: '/views/ASD/sheet0',
      prod: '',
    }
  },
  servicenow: {
    masterMaintenanceURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=83913318db96c9100aa3f165f39619c7',
    convertGoodsAttributeURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=958661e5db62c5108a043346f39619e1',
    solveUnmatchedGoodsURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=24f0fca1dbe501108a043346f396197c',
    solveUnmatchedStoresURL: '?id=sc_cat_item&sys_id=fda6988edb2a09108a043346f39619bd&sysparm_category=b9943636db5949100aa3f165f396193c&catalog_id=-1',
    storeMasterMaintenanceURL: '?id=sc_cat_item&sys_id=c40e5b7ddb2609108a043346f3961900&sysparm_category=b9943636db5949100aa3f165f396193c&catalog_id=-1',
    individualAuthorityURL: '?id=sc_cat_item&sys_id=3a315c5edbfb89508a043346f39619ac&sysparm_category=b9943636db5949100aa3f165f396193c',
    requestChainStaffURL: '?id=sc_cat_item&sys_id=87404c16db69c5100aa3f165f396192f&sysparm_category=b9943636db5949100aa3f165f396193c&catalog_id=-1',
    requestForChainURL: '?id=sc_cat_item&sys_id=cb222ee9db26c5108a043346f3961987&sysparm_category=b9943636db5949100aa3f165f396193c',
    generalURL: '?id=sc_cat_item&sys_id=ea2ac05cdb3305508a043346f396193e&sysparm_category=b9943636db5949100aa3f165f396193c',
    POSRequestURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=7003e8dedb3f89508a043346f3961935',
    recoveryRequestURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=5f8cb8d2dbbf89508a043346f396196d',
    inquiryURL: '?id=contact_sc_cat_item&sys_id=d68e3b79db5185100aa3f165f39619dc&sysparm_category=76cdd713db3370100aa3f165f3961982'
  }
};
